<template>
  <div
    class="payments"
    :class="{ payments__mobile: mobile }"
  >
    <PaymentsHeader @backStep="confirmExit" />

    <div class="payments__content">
      <PaymentsTable />
    </div>
  </div>
</template>

<script>

import PaymentsHeader from '@/components/organisms/payments/PaymentsHeader.vue';
import PaymentsTable from '@/components/organisms/payments/PaymentsTable.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TetherPayments',
  components: {
    PaymentsHeader,
    PaymentsTable,
  },
  computed: {
    ...mapGetters({
      paymentsRedirectRoute: 'payments/redirectRoute',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.setBreadcrumbs({
      breadcrumbs: [
        {
          text: 'payments.breadcrumbs.root',
          route: this.$route.path,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      setBreadcrumbs: 'utils/setBreadcrumbs',
      activateModal: 'utils/activateModal',
      resetPaymentStore: 'payments/resetStore',
      setLoading: 'payments/setLoading',
    }),
    confirmExit() {
      const callbacks = {
        onConfirm: () => {
          this.setLoading({ loading: true });
          this.$router.push(this.paymentsRedirectRoute.path || '/');
          this.resetPaymentStore();
          this.setLoading({ loading: false });
        },
      };
      this.activateModal({
        name: 'PaymentStatus',
        data: {
          status: 'exit',
        },
        callbacks,
      });
    },
  },
};
</script>
