<template>
  <div class="payments__list">
    <v-data-table
      :headers="tableHeaders"
      :items="payments"
      single-expand
      show-expand
      item-key="id"
    >
      <template #item.amount="{ item }"> <!-- eslint-disable-line -->
        <span>{{ amountDisplayName(item.amount, item.currency) }}</span>
      </template>
      <template #item.due_date="{ item }"> <!-- eslint-disable-line -->
        <span>{{ prettyDate(item.due_date) }}</span>
      </template>
      <template #item.status="{ item }"> <!-- eslint-disable-line -->
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-chip
              :color="getStatusColor(item.status.status)"
              text-color="white"
              style="width: 90%; display: flex; justify-content: center;"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ item.status.status }}</span>
              <!-- <span>{{ item.current_state.date }}</span> -->
            </v-chip>
          </template>
          <span>{{ prettyDate(item.status.date) }}</span>
        </v-tooltip>
      </template>
      <template #item.actions="{ item }"> <!-- eslint-disable-line -->
        <v-btn
          v-if="item.status.status !== 'SUCCEEDED'"
          color="primary"
          outlined
          text
          width="90%"
          @click="startPaymentConfirmation(item)"
        >
          {{
            item.status.status === ADMISSION_STATUS.CREATED
              ? $t('payments.buttons.pay')
              : $t('payments.buttons.retry')
          }}
        </v-btn>
        <v-icon
          v-else
          style="display: flex;"
          color="green"
        >
          mdi-check
        </v-icon>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          {{ item.description }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { prettyDate } from '@/utils/strings';
import { amountDisplayName } from '@/utils/currency';
import { ADMISSION_STATUS } from '@/constants/digitalEnrollment';

export default {
  name: 'PaymentsTable',
  data() {
    return { ADMISSION_STATUS };
  },
  computed: {
    ...mapGetters({
      payments: 'payments/payments',
    }),
    tableHeaders() {
      return [
        {
          text: this.$t('payments.table.headers.name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: this.$t('payments.table.headers.amount'), value: 'amount' },
        { text: this.$t('payments.table.headers.due_date'), value: 'due_date', align: 'center' },
        { text: this.$t('payments.table.headers.status'), value: 'status' },
        { text: this.$t('payments.table.headers.actions'), value: 'actions' },
        { text: '', value: 'data-table-expand' },
      ];
    },
  },
  mounted() {
    this.setBreadcrumbs({
      breadcrumbs: [
        {
          text: 'payments.breadcrumbs.root',
          route: this.$router.resolve({ name: 'MyPayments' }).href,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      activateModal: 'utils/activateModal',
      setBreadcrumbs: 'utils/setBreadcrumbs',
    }),
    getStatusColor(status) {
      const statusColors = {
        SUCCEEDED: 'green',
        PENDING: 'yellow',
        CREATED: 'blue',
        FAILED: 'deep-orange darken-4',
        REJECTED: 'orange',
        EXPIRED: 'red',
      };
      return statusColors[status];
    },
    startPaymentConfirmation({ id: paymentId }) {
      const callbacks = {
        onConfirm: () => this.$router.push({ name: 'Payment', params: { id: paymentId } }),
      };
      this.activateModal({ name: 'PaymentConfirmation', data: { paymentId }, callbacks });
    },
    amountDisplayName,
    prettyDate,
  },
};
</script>
